import {
	Maybe,
	IUnionTypeResolver,
	IEnumTypeResolver,
	IInputObjectTypeResolver,
	ISchemaLevelResolver,
	IObjectTypeResolver,
	IInterfaceTypeResolver,
} from '@graphql-tools/utils';
import { type KeyValueCache } from '@apollo/utils.keyvaluecache';
import { GraphQLFieldResolver } from 'graphql/type';
import { GraphQLError as GQLError } from 'graphql/error';
import { ASTNode, Source, DocumentNode } from 'graphql/language';
import type DataLoader from 'dataloader';
import { RESTDataSource } from '@apollo/datasource-rest';

import type { IUser } from './user';

export type { DocumentNode };
export interface IPage {
	hasNextPage: boolean;
	hasPreviousPage: boolean;
	pageSize: number;
	[key: string]: any;
}
export interface IPageInfoInt extends IPage {
	page: number;
	hasNextPage: boolean;
	hasPreviousPage: boolean;
	pageSize: number;
}
export interface IPageInfoCursor extends IPage {
	startCursor: string;
	endCursor: string;
	hasNextPage: boolean;
	hasPreviousPage: boolean;
	pageSize: number;
}
export interface ResultEdge<T = any> {
	node: T;
	cursor: string;
}
export interface ListQueryResult<T = any> {
	edges: ResultEdge<T>[];
	pageInfo: IPageInfoInt | IPageInfoCursor;
	totalCount: number;
}
export interface IPagination {
	page?: number;
	pageSize?: number;
	filter?: string;
	isCursor?: boolean;
	after?: string;
	before?: string;
	sort?: string;
	[key: string]: any;
}

export type PageInfo = IPageInfoInt | IPageInfoCursor;

export type AddCustomLoaderFN = (
	dataloaderContextKey: string,
	dataloaderContext: Record<string, any>,
) => void;

export type CustomLoaderFN = (
	dataloaderContextKey: string,
	dataloaderContext: Record<string, any>,
) => DataLoader<string, any, string>;

export interface IValidationError {
	key: string;
	message?: string;
}
export interface Context {
	token?: string;
	sessionId?: string;
	participantId?: string;
	user?: IUser;
	locale?: string;
	[key: string]: any;
}
export interface ContextCTX {
	ctx: Context;
	[key: string]: any;
}

export type IResolver<TSource = any, TArgs = any, TReturn = any> =
	| ISchemaLevelResolver<TSource, ContextCTX, TArgs, TReturn>
	| IObjectTypeResolver<TSource, ContextCTX>
	| IInterfaceTypeResolver<TSource, ContextCTX>
	| IUnionTypeResolver
	// | IScalarTypeResolver // removing this from type defination coz this one is class and results to explicit type on resolver
	| IEnumTypeResolver
	| IInputObjectTypeResolver;

export type IResolvers = Record<string, IResolver>;
export interface IResolverModule {
	types: IResolvers;
	inputs: IResolvers;
	queries: IResolvers;
	mutations: IResolvers;
	subscriptions: IResolvers;
}

export type IGraphQLFieldResolver = GraphQLFieldResolver<any, ContextCTX>;
export interface IMock {
	[key: string]: GraphQLFieldResolver<any, ContextCTX>;
}
export interface IMockModule {
	types: IMock;
	queries: IMock;
	mutations: IMock;
}

export type ValueOrPromise<T = any> = T | Promise<T>;

export interface GqlError {
	key: string;
	message: string;
}

export type GqlArrayError = { [key: string]: string[] };

export const processErrors = (errors: GqlError[]): GqlArrayError =>
	errors.reduce<any>((result, error) => {
		if (Object.prototype.hasOwnProperty.call(result, error.key)) {
			result[error.key].push(error.message);
		} else {
			result[error.key] = [error.message];
		}
		return result;
	}, {});

export class GraphQLValidationError extends GQLError {
	constructor(errors: GqlError[]) {
		super(
			'VALIDATIONERROR',
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			{
				validationErrors: processErrors(errors),
			},
		);
	}
}
export class GraphQLError extends GQLError {
	constructor(
		message: string,
		statusCode?: string | number,
		nodes?: Maybe<ReadonlyArray<ASTNode> | ASTNode>,
		source?: Maybe<Source>,
		positions?: Maybe<ReadonlyArray<number>>,
		path?: Maybe<ReadonlyArray<string | number>>,
		originalError?: Maybe<Error>,
		extensions?: Maybe<{ [key: string]: any }>,
	) {
		super(
			message,
			nodes,
			source,
			positions,
			path,
			originalError,
			extensions,
		);
		this.code = statusCode || 500;
	}

	code: string | number;
}

export interface Edge<T = any> {
	cursor: string;
	node: T;
}

export interface IResult<T = any> {
	edges: Edge<T>[];
	pageInfo: IPageInfoCursor | IPageInfoCursor;
}

export type Optional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

/**
 * Pick and make properties in T required
 */
export type PickRequired<T, K extends keyof T> = {
	[P in K]-?: NonNullable<T[P]>;
};

/**
 * Make properties in T required
 */
export type MakeRequired<T, K extends keyof T> = {
	[P in K]-?: NonNullable<T[P]>;
} & Omit<T, K>;

export type DataSource<T extends RESTDataSource> = (options: {
	context: ContextCTX;
	cache: KeyValueCache;
}) => T;
export interface DataSourceModule<T extends RESTDataSource> {
	name: string;
	dataSource: DataSource<T>;
}
