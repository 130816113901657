import React from 'react';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { Session } from 'next-auth';
import { IPageAuthOption } from '@interfaces';

import Loading from '@common-lib/components/Loading';
import Unauthorized from '@common-lib/components/Error403';

const getInstuctionUrl = (session: Session) => {
	return null;
};
const AuthGaurd: React.FC<React.PropsWithChildren<IPageAuthOption>> = ({
	children,
	redirect,
	requireAuth,
	requireRoles = [],
}) => {
	const { data, status } = useSession();
	const { push, asPath } = useRouter();
	const [loading, setLoading] = React.useState<boolean>(false);
	React.useEffect(() => {
		if (requireAuth && status === 'unauthenticated') {
			if (redirect && typeof window !== 'undefined') {
				push(
					typeof redirect !== 'boolean'
						? redirect
						: {
								pathname: '/auth/login/[[...redirectUrl]]',
								query: { redirectUrl: asPath },
							},
				);
				// return <Loading overlay />;
			}
			setLoading(true);
		}
	}, [push, requireAuth, status, redirect]);
	const userRequired = requireAuth || requireRoles.length;
	const userInRole =
		!requireRoles.length ||
		requireRoles.some(r => r === data?.user.defaultRole);
	if (!userRequired) {
		return <>{children}</>;
	}
	if (status === 'loading') {
		return <Loading overlay />;
	}
	// if (requireAuth && status === 'unauthenticated') {
	// 	if (redirect && typeof window !== 'undefined') {
	// 		push(
	// 			typeof redirect !== 'boolean'
	// 				? redirect
	// 				: {
	// 						pathname: '/auth/login/[[...redirectUrl]]',
	// 						query: { redirectUrl: asPath },
	// 				  },
	// 		);
	// 		return <Loading overlay />;
	// 	}
	// 	return <Unauthorized overlay />;
	// }
	if (loading) {
		return <Loading overlay />;
	}
	if (!userInRole) {
		return <Unauthorized overlay />;
	}
	if (data) {
		const url = getInstuctionUrl(data);
		if (url && typeof window !== 'undefined' && asPath !== url) {
			push({
				pathname: url,
			});
			return <Loading overlay />;
		}
	}
	return <>{children}</>;
};

export default AuthGaurd;
