export enum Timeslot {
	TIME_0900_1800 = 'TIME_0900_1800',
	TIME_0900_1300 = 'TIME_0900_1300',
	TIME_1000_1400 = 'TIME_1000_1400',
	TIME_1100_1500 = 'TIME_1100_1500',
	TIME_1200_1600 = 'TIME_1200_1600',
	TIME_1300_1700 = 'TIME_1300_1700',
	TIME_1400_1800 = 'TIME_1400_1800',
	TIME_1500_1900 = 'TIME_1500_1900',
	TIME_1600_2000 = 'TIME_1600_2000',
}

export enum SHARE_SITES {
	GOOGLE = 'Google',
	OFFICE365 = 'Office 365',
	OUTLOOK = 'Outlook',
	ICAL = 'iCal',
	// YAHOO = 'Yahoo',
}

export enum Country {
	BR = 'BR',
}

export enum State {
	AC = 'AC',
	AL = 'AL',
	AP = 'AP',
	AM = 'AM',
	BA = 'BA',
	CE = 'CE',
	DF = 'DF',
	ES = 'ES',
	GO = 'GO',
	MA = 'MA',
	MT = 'MT',
	MS = 'MS',
	MG = 'MG',
	PA = 'PA',
	PB = 'PB',
	PR = 'PR',
	PE = 'PE',
	PI = 'PI',
	RJ = 'RJ',
	RN = 'RN',
	RS = 'RS',
	RO = 'RO',
	RR = 'RR',
	SC = 'SC',
	SP = 'SP',
	SE = 'SE',
	TO = 'TO',
}
