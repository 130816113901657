import React from 'react';

import Container, { ContainerProps } from '@ui/components/Container';
import { styled } from '@ui/styles';

const TheContainer = styled(Container, {
	shouldForwardProp: prop => !['showBG', 'bgColor'].includes(prop as any),
})<
	ContainerProps & {
		component?: React.ElementType;
		bgColor?: string;
	}
>(({ theme, bgColor }) => ({
	paddingLeft: '0px !important',
	paddingRight: '0px !important',
	overflow: 'visible',
	display: 'flex',
	flexDirection: 'column',
	flex: 1,
	// borderRadius: 10,
	// margin: 10,
	[theme.breakpoints.up('sm')]: {
		backgroundColor: bgColor || '#f9bd3c',
		// maxWidth: 390,
		// maxHeight: 844,
	},
	position: 'relative',
}));

const TheContainerWrapper: React.FC<
	ContainerProps & { showBG?: boolean; bgColor?: string }
> = props => {
	return <TheContainer {...props} />;
};
export default TheContainerWrapper;
