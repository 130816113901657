import React from 'react';
import { Global, css } from '@emotion/react';

const GlobalStyles: React.FC<{ fontFamily: Record<string, string> }> = ({
	fontFamily,
}) => {
	const font = `${Object.values(fontFamily).join(
		',',
	)}, 'barlow', 'Open Sans', sans-serif`.trim();
	return (
		<Global
			styles={css`
				* {
					box-sizing: border-box;
					-webkit-tap-highlight-color: transparent !important;
				}
				html,
				body {
					padding: 0;
					margin: 0;
					font-family: ${font};
					font-size: 16px;
					height: 100%;
					background-color: #e9cf88;
				}
				body {
					transition: all 1s ease;
					min-height: calc(
						100vh - max(55px, var(--safe-area-inset-bottom))
					);
				}
				#__next {
					display: flex;
					flex-direction: column;
					height: 100%;
				}
				.wrapper-box {
					height: calc(100% - 95px) !important;
				}
				.bt-margin {
					margin-bottom: 80px !important;
				}
				.wrapper-terms {
					transition: all 1s ease;
					height: calc(100vh - 150px);
				}
				// @media (max-height: 650px) {
				// 	body {
				// 		min-height: 100% !important;
				// 		max-height: 100% !important;
				// 	}
				// 	main {
				// 		min-height: 100% !important;
				// 		max-height: 100% !important;
				// 		display: block !important;
				// 		padding-bottom: 68px !important;
				// 		overflow-y: unset !important;
				// 		overflow-x: unset !important;
				// 	}
				// }
				// @media (min-width: 600px) and (min-height: 844px) {
				// 	body {
				// 		display: flex;
				// 		flex-direction: column;
				// 		justify-content: center;
				// 	}
				// 	body > #__next > div {
				// 		max-height: 844px;
				// 		min-height: 844px;
				// 	}
				// 	main {
				// 		min-height: calc(
				// 			844px - max(67px, var(--safe-area-inset-bottom))
				// 		) !important;
				// 		max-height: calc(
				// 			844px - max(67px, var(--safe-area-inset-bottom))
				// 		) !important;
				// 	}
				// 	footer {
				// 		position: absolute !important;
				// 	}
				// }
				a {
					color: #073b5b;
				}
				::-webkit-scrollbar {
					display: none;
				}
				/* Chrome, Safari, Edge, Opera */
				input::-webkit-outer-spin-button,
				input::-webkit-inner-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}

				/* Firefox */
				input[type='number'] {
					-moz-appearance: textfield;
				}
				input:-webkit-autofill {
					-webkit-box-shadow: 0 0 0 50px #fff9e1 inset;
					-webkit-text-fill-color: #f1bc62;
				}
				input:-webkit-autofill:focus {
					-webkit-box-shadow: 0 0 0 50px #fff9e1 inset;
					-webkit-text-fill-color: #f1bc62;
				}
				.input-label {
					padding-top: 0rem !important;
					padding-bottom: 0rem !important;
				}
				.select-label {
					padding-top: 0rem !important;
					padding-bottom: 0rem !important;
				}
				.text-label {
					padding-top: 0rem !important;
					padding-bottom: 0rem !important;
				}
				.large-info {
					min-height: 3.125rem;
					justify-content: center;
					display: flex;
					flex-direction: column;
				}
				.grecaptcha-badge {
					display: none;
				}
				.grecaptcha-logo {
					display: none;
				}
				.ReactModal__Overlay {
					z-index: 10000 !important;
				}
				:root {
					--smileclip-path: 100%;
					// --safe-area-inset-top: env(safe-area-inset-top);
					// --safe-area-inset-bottom: env(safe-area-inset-bottom);
				}
				@supports (-webkit-touch-callout: none) {
					html,
					body {
						background-color: #f9bd3c;
						// min-height: 101vh;
						// min-height: -webkit-fill-available;
					}
					body {
						min-height: calc(100vh - var(--safe-area-inset-bottom));
					}
					main {
						// min-height: -webkit-fill-available !important;
						padding-bottom: 28px !important;
					}
					.wrapper-box {
						height: calc(100% - 95px) !important;
					}
					.bt-margin {
						margin-bottom: 55px !important;
					}
					.wrapper-terms {
						height: calc(
							(100vh - 26px) - var(--safe-area-inset-bottom)
						) !important;
					}
					.MuiTypography-root {
						letter-spacing: -0.1rem;
					}
					.MuiTypography-h1,
					.MuiTypography-h2,
					.MuiTypography-h4 {
						font-weight: 600;
					}
					// @media (max-height: 650px) {
					// 	body {
					// 		min-height: 100% !important;
					// 		max-height: 100% !important;
					// 	}
					// 	main {
					// 		min-height: 100% !important;
					// 		max-height: 100% !important;
					// 		display: block !important;
					// 		padding-bottom: 68px !important;
					// 		overflow-y: unset !important;
					// 		overflow-x: unset !important;
					// 	}
					// }
				}
				@media (max-width: 481px) {
					:root {
						--smileclip-path: 116%;
					}
					body {
						background-color: #f9bd3c;
					}
				}
				@keyframes smileclip {
					0% {
						height: 0px;
						opacity: 0.5;
						min-height: 0px;
						clip-path: ellipse(200% 100% at top center);
					}
					60% {
						height: 100%;
						opacity: 1;
						min-height: 324px;
						clip-path: ellipse(200% 100% at top center);
					}
					100% {
						height: 100%;
						opacity: 1;
						min-height: 324px;
						clip-path: ellipse(
							var(--smileclip-path) 100% at top center
						);
					}
				}
				main {
					-webkit-background-size: cover;
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;
					// min-height: calc(
					// 	100vh - max(67px, var(--safe-area-inset-bottom))
					// );
					// max-height: calc(
					// 	100vh - max(67px, var(--safe-area-inset-bottom))
					// );
					height: 100%;
					overflow-y: scroll;
					overflow-x: hidden;
				}
				.path {
					stroke-dasharray: 1000;
					stroke-dashoffset: 1000;
					animation: dash 5s linear alternate infinite;
				}
				@keyframes dash {
					from {
						stroke-dashoffset: 822;
					}
					to {
						stroke-dashoffset: 0;
					}
				}
				// change ripple color
				// button span > * {
				// 	background-color: #000 !important;
				// }

				@media print {
					body > div.__next > div {
						max-height: none !important;
						min-height: none !important;
					}
					main {
						min-height: none !important;
						max-height: none !important;
						overflow-y: visible !important;
						overflow-x: visible !important;
					}
					footer {
						display: none !important;
					}
				}

				.circle-price {
					animation: bgz 5s ease-in infinite;
				}
				.circle-bg {
					animation: bgp 10s ease-in-out infinite;
				}
				.balloon {
					transition: transform 0.5s ease;
					animation: balloons 5s ease-in-out infinite;
					transform-origin: bottom center;
				}

				.balloon:nth-of-type(2) {
					animation-duration: 6s;
				}

				.balloon:nth-of-type(3) {
					animation-duration: 7s;
				}

				.balloon:nth-of-type(4) {
					animation-duration: 8s;
				}

				.balloon:nth-of-type(5) {
					animation-duration: 9s;
				}

				.balloon:nth-of-type(6) {
					animation-duration: 10s;
				}

				@keyframes balloons {
					0%,
					100% {
						transform: translateY(0) rotate(-4deg) scale(1);
					}
					50% {
						transform: translateY(-10px) rotate(4deg) scale(1.15);
					}
				}
				div:focus-visible {
					outline: none;
				}
				@keyframes bgp {
					0%,
					100% {
						background-position: center;
						-webkit-background-position: center;
					}
					20% {
						background-position: top;
						-webkit-background-position: top;
					}
					40% {
						background-position: left;
						-webkit-background-position: left;
					}
					60% {
						background-position: bottom;
						-webkit-background-position: bottom;
					}
					80% {
						background-position: right;
						-webkit-background-position: right;
					}
				}
				@keyframes bgz {
					0%,
					100% {
						background-size: 154%;
						-webkit-background-size: 154%;
					}
					10%,
					90% {
						background-size: 156%;
						-webkit-background-size: 156%;
					}
					20%,
					80% {
						background-size: 158%;
						-webkit-background-size: 158%;
					}
					30%,
					70% {
						background-size: 160%;
						-webkit-background-size: 160%;
					}
					40%,
					60% {
						background-size: 162%;
						-webkit-background-size: 162%;
					}
					50% {
						background-size: 164%;
						-webkit-background-size: 164%;
					}
				}
				.MuiTableCell-root
					.MuiCheckbox-root:not(
						.Mui-checked,
						.MuiCheckbox-indeterminate
					)
					> svg
					path {
					fill: #073b5b20;
				}
				@media only screen and (min-width: 768px) {
					/* For desktop: */
					body {
						display: flex;
						flex-direction: column;
						justify-content: center;
					}
					#__next {
						height: auto;
					}
					body > #__next > div {
						max-height: 844px;
						min-height: 844px;
					}
					main {
						padding-bottom: 67px;
					}
					footer {
						position: absolute !important;
					}
					.wrapper-box {
						margin-bottom: 14px !important;
					}
					.bt-margin {
						margin-bottom: 14px !important;
					}
					.terms-wrapper {
						margin-top: -35px;
						height: 780px !important;
						// max-height: 780px;
						// min-height: 780px;
					}
					.terms > div {
						// min-height: calc(100% - 55px);
						margin-bottom: 55px;
					}
					.terms {
						height: calc(844px - 135px) !important;
					}
					.wrapper-terms {
						height: 844px !important;
					}
				}
			`}
		/>
	);
};

export default GlobalStyles;
