import { Box } from '@ui/components';
import { styled } from '@ui/styles';

const TopCurveBox = styled(Box)(({ theme }) => ({
	position: 'relative',
	width: '100%',
	textAlign: 'center',
	// paddingTop: theme.spacing(3),
	// overflow: 'hidden',
	// backgroundColor: theme.palette.primary.main,
	// animationName: 'smileclip',
	// animationDuration: '0.8s',
	// animationDelay: '0.5s',
	// animationFillMode: 'forwards',
	// animationTimingFunction: 'ease-out',
	// zIndex: 4,
	// '&& img': {
	// 	WebkitUserSelect: 'none',
	// 	MozUserSelect: 'none',
	// 	msUserSelect: 'none',
	// 	userSelect: 'none',
	// 	touchAction: 'none',
	// 	MsTouchAction: 'none',
	// 	WebkitTouchCallout: 'none',
	// },
}));

export default TopCurveBox;
