import { Components } from '@mui/material/styles';

import { FontFamily } from '@ui/styles/types/fontFamily';

const components = (fontFamily: FontFamily): Components => {
	const family = `${Object.values(fontFamily).join(',')}`.trim();
	return {
		MuiCssBaseline: {
			styleOverrides: {
				fontFamily: family,
			},
		},
		MuiAlert: {
			styleOverrides: {
				root: {
					fontFamily: fontFamily.gilroy,
				},
			},
		},
		MuiButton: {
			defaultProps: {
				disableElevation: true,
			},
			styleOverrides: {
				root: {
					textTransform: 'uppercase',
					minWidth: 183,
					minHeight: 36,
					lineHeight: '1.4rem',
					borderRadius: 23,
					fontSize: '1.1875rem',
					fontWeight: 500,
					fontFamily: fontFamily.ceraCY,
				},
				containedPrimary: {
					'&:active': {
						backgroundColor: '#EBB353',
					},
					'&& .MuiTouchRipple-child': {
						backgroundColor: '#EBB353',
					},
				},
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					minHeight: 13,
					fontSize: '0.6875rem',
					letterSpacing: '0.0625rem',
					lineHeight: '0.9375rem',
					color: '#ffffff',
					fontWeight: 'bold',
					fontFamily: fontFamily.gilroy,
				},
			},
		},
		MuiFormControlLabel: {
			styleOverrides: {
				root: {
					'&.Mui-error': {
						color: '#BF291C',
					},
					'&.Mui-error > .MuiFormControlLabel-label': {
						color: '#BF291C',
					},
					'&.Mui-error > .MuiCheckbox-root > svg': {
						border: '1px solid #BF291C',
					},
				},
				label: {
					fontSize: '0.6875rem',
					letterSpacing: '0.0625rem',
					lineHeight: '0.9375rem',
					color: '#EBB353',
					fontWeight: 'bold',
					fontFamily: fontFamily.gilroy,
					textAlign: 'left',
				},
			},
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					paddingTop: 4.5,
					paddingBottom: 4.5,
				},
			},
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					marginLeft: 0,
					marginRight: 0,
					fontFamily: fontFamily.gilroy,
					fontSize: '0.6275rem',
					letterSpacing: '0.0625rem',
					lineHeight: '0.85rem',
					fontWeight: 'bold',
				},
			},
		},
		MuiOutlinedInput: {
			defaultProps: {
				notched: false,
			},
			styleOverrides: {
				root: {
					backgroundColor: '#fff9e1',
					height: 42,
					'&.Mui-error': {
						borderColor: '#BF291C',
					},
					'&.Mui-error > input': {
						'::placeholder': {
							color: '#BF291C',
						},
						'::-webkit-input-placeholder': {
							color: '#BF291C',
						},
					},
				},
				input: {
					color: '#F1BC62',
					// fontSize: '0.6875rem',
					// letterSpacing: '0.0625rem',
					// lineHeight: '0.3125rem',
					padding: '9.5px 14px',
					fontWeight: 'bold',
					fontFamily: fontFamily.gilroy,
					'::placeholder': {
						opacity: 1,
						color: '#F1BC62',
						fontSize: '0.6875rem',
						letterSpacing: '0.0625rem',
						lineHeight: '0.3125rem',
						fontWeight: 'bold',
						fontFamily: fontFamily.gilroy,
					},
					'::-webkit-input-placeholder': {
						opacity: 1,
						color: '#F1BC62',
						fontSize: '0.6875rem',
						letterSpacing: '0.0625rem',
						lineHeight: '0.3125rem',
						fontWeight: 'bold',
						fontFamily: fontFamily.gilroy,
					},
				},
				notchedOutline: {
					borderColor: 'transparent',
					top: -6,
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					fontFamily: fontFamily.gilroy,
					fontWeight: 'bold',
					color: '#073b5b',
				},
			},
		},
		MuiTablePagination: {
			styleOverrides: {
				toolbar: {
					'&& *': {
						fontFamily: fontFamily.gilroy,
					},
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					fontFamily: fontFamily.gilroy,
				},
			},
		},
		MuiModal: {
			styleOverrides: {
				root: {
					zIndex: 1000,
				},
			},
		},
	};
};

export default components;
