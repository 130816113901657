const config = {
	host: `${process.env.NEXT_PUBLIC_HOST || 'http://localhost:3000'}`,
	forceHttps: process.env.NEXT_PUBLIC_FORCE_HTTPS === 'true' || false,
	s3Proxy: `${
		process.env.NEXT_PUBLIC_S3_PROXY || 'http://localhost:3000/api/s3'
	}`,
	graphqlUrl: `${
		process.env.NEXT_PUBLIC_GRAPHQL_API_URL ||
		'http://localhost:3000/api/graphql'
	}`,
	graphqlWSUrl: `${
		process.env.NEXT_PUBLIC_GRAPHQL_WS_URL ||
		'http://localhost:3000/api/graphql-subscription'
	}`,
	appName: `${process.env.NEXT_PUBLIC_APP_NAME || 'vrumona'}`,
	darkMode: process.env.NEXT_PUBLIC_DARK_MODE === 'true' || false,
	googleMapsApiKey: process.env.NEXT_PUBLIC_GMAP_KEY || '',
	language: 'nl',
	cookieName: `${process.env.JWT_COOKIE_NAME || 'next-auth.session-token'}`,
	facebook: {
		pixelId: `${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID || '1234'}`,
	},
	google: {
		ga: {
			consumerId: `${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID_CONSUMER}`,
			consumerStreamId: `${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_STREAM_ID_CONSUMER}`,
			barId: `${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID_BAR}`,
			barStreamId: `${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_STREAM_ID_BAR}`,
			test: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TEST === 'true',
		},
	},
};

export default config;
