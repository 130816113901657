import { Palette } from '@mui/material/styles';

export const darkPalette: Omit<Palette, 'getContrastText' | 'augmentColor'> = {
	mode: 'dark',
	common: {
		black: '#000000',
		white: '#FFFFFF',
	},
	primary: {
		lighter: '#FFFEEA',
		light: '#F5D28C',
		dark: '#f9bd3c',
		main: '#F1BC62',
		contrastText: '#ffffff',
	},
	secondary: {
		lighter: '#708FB5',
		light: '#073b5b60',
		main: '#073b5b',
		dark: '#013e5d',
		contrastText: '#ffffff',
	},
	error: {
		light: '#E57373',
		main: '#BF291C',
		dark: '#9B3229',
		contrastText: '#ffffff',
	},
	warning: {
		light: '#FEF6EA',
		main: '#E19017',
		dark: '#E18239',
		contrastText: '#FFFFFF',
	},
	info: {
		light: '#FAFAFA',
		main: '#F8F8F8',
		dark: '#C2C1C2',
		contrastText: '#585050',
	},
	success: {
		light: '#99E299',
		main: '#4dd74d',
		dark: '#2BBA8F',
		contrastText: '#FFFFFF',
	},
	text: {
		primary: '#073b5b',
		secondary: '#625145',
		disabled: '#A7A7A7',
	},
	// NOTE: Please, keep it to material-ui approach 50 - 900 and A100 - A700
	grey: {
		50: '#fafafa',
		100: '#f5f5f5',
		200: '#eeeeee',
		300: '#EFF1F1',
		400: '#DBDBDB',
		500: '#9e9e9e',
		600: '#757575',
		700: '#616161',
		800: '#1D293F',
		900: '#212121',
		A100: '#d5d5d5',
		A200: '#aaaaaa',
		A400: '#303030',
		A700: '#616161',
	},
	contrastThreshold: 3,
	tonalOffset: 0.2,
	divider: 'rgba(0, 0, 0, 0.12)',
	background: {
		paper: '#FEFCDF',
		default: '#F5D28C',
	},
	action: {
		active: 'rgba(0, 0, 0, 0.54)',
		hover: 'rgba(0, 0, 0, 0.04)',
		hoverOpacity: 0.04,
		selected: 'rgba(0, 0, 0, 0.08)',
		selectedOpacity: 0.08,
		disabled: 'rgba(0, 0, 0, 0.26)',
		disabledBackground: 'rgba(0, 0, 0, 0.12)',
		disabledOpacity: 0.38,
		focus: 'rgba(0, 0, 0, 0.12)',
		focusOpacity: 0.12,
		activatedOpacity: 0.12,
	},
	// getContrastText: function (background: string): string {
	// 	throw new Error('Function not implemented.');
	// },
	// augmentColor: function (options: PaletteAugmentColorOptions): PaletteColor {
	// 	throw new Error('Function not implemented.');
	// },
};

export const lightPalette: Omit<Palette, 'getContrastText' | 'augmentColor'> = {
	mode: 'light',
	common: {
		black: '#000000',
		white: '#FFFFFF',
	},
	primary: {
		lighter: '#FFFEEA',
		light: '#F5D28C',
		dark: '#f9bd3c',
		main: '#F1BC62',
		contrastText: '#ffffff',
	},
	secondary: {
		lighter: '#708FB5',
		light: '#073b5b60',
		main: '#073b5b',
		dark: '#013e5d',
		contrastText: '#ffffff',
	},
	error: {
		light: '#E57373',
		main: '#BF291C',
		dark: '#9B3229',
		contrastText: '#ffffff',
	},
	warning: {
		light: '#FEF6EA',
		main: '#E19017',
		dark: '#E18239',
		contrastText: '#FFFFFF',
	},
	info: {
		light: '#FAFAFA',
		main: '#F8F8F8',
		dark: '#C2C1C2',
		contrastText: '#585050',
	},
	success: {
		light: '#99E299',
		main: '#4dd74d',
		dark: '#2BBA8F',
		contrastText: '#FFFFFF',
	},
	text: {
		primary: '#073b5b',
		secondary: '#625145',
		disabled: '#A7A7A7',
	},
	// NOTE: Please, keep it to material-ui approach 50 - 900 and A100 - A700
	grey: {
		50: '#fafafa',
		100: '#f5f5f5',
		200: '#eeeeee',
		300: '#EFF1F1',
		400: '#DBDBDB',
		500: '#9e9e9e',
		600: '#757575',
		700: '#616161',
		800: '#1D293F',
		900: '#212121',
		A100: '#d5d5d5',
		A200: '#aaaaaa',
		A400: '#303030',
		A700: '#616161',
	},
	contrastThreshold: 3,
	tonalOffset: 0.2,
	divider: 'rgba(0, 0, 0, 0.12)',
	background: {
		paper: '#FFFEEA',
		default: '#F5D28C',
	},
	action: {
		active: 'rgba(0, 0, 0, 0.54)',
		hover: 'rgba(0, 0, 0, 0.04)',
		hoverOpacity: 0.04,
		selected: 'rgba(0, 0, 0, 0.08)',
		selectedOpacity: 0.08,
		disabled: 'rgba(0, 0, 0, 0.26)',
		disabledBackground: 'rgba(0, 0, 0, 0.12)',
		disabledOpacity: 0.38,
		focus: 'rgba(0, 0, 0, 0.12)',
		focusOpacity: 0.12,
		activatedOpacity: 0.12,
	},
	// getContrastText: function (background: string): string {
	// 	throw new Error('Function not implemented.');
	// },
	// augmentColor: function (options: PaletteAugmentColorOptions): PaletteColor {
	// 	throw new Error('Function not implemented.');
	// },
};

const getPalette = (dark: boolean) => (dark ? darkPalette : lightPalette);

export default getPalette;
