import React from 'react';

import { hashCode } from '@utils/helpers/stringHelper';

import AppContext from '@common-lib/AppContext';
import Error400 from '@common-lib/components/Error400';

interface State {
	hasError: boolean;
	hash: string | null;
	chunkError: boolean;
}

class ErrorBoundary extends React.PureComponent<
	React.PropsWithChildren,
	State
> {
	static contextType = AppContext;

	state = {
		hasError: false,
		hash: null,
		chunkError: false,
	};

	static getDerivedStateFromError(error: Error) {
		const chunkError = error.message.includes('chunk');
		return {
			hasError: true,
			chunkError: !!chunkError,
			hash: hashCode(error.toString()),
		};
	}

	handleResetError = () => {
		const { chunkError } = this.state;
		if (chunkError && typeof window !== 'undefined') {
			// we have some kind of js loading error
			window.location.reload();
		}
		this.setState({
			hasError: false,
			chunkError: false,
			hash: null,
		});
	};

	handleReload = () => {
		// we have some kind of js loading error
		window.location.reload();
	};

	componentDidCatch(err: Error, info: React.ErrorInfo) {
		const { hash } = this.state;
		console.warn(err, info);
		try {
			// logToElastic(this.context.frontEndUrl)(err, info, hash);
		} catch (err) {
			console.error(err);
		}
	}

	render() {
		const { children } = this.props;
		const { hasError, chunkError, hash } = this.state;

		if (!hasError) {
			if (children === undefined) {
				console.log('Children undefined', console.trace());
				return (
					<Error400 hash={hash!} onReset={this.handleResetError} />
				);
			}
			return children;
		}

		if (chunkError) {
			// return <ChunkError onReset={this.handleResetError} />;
			return <Error400 hash={hash!} onReset={this.handleReload} />;
		}

		return <Error400 hash={hash!} onReset={this.handleResetError} />;
	}
}

export default ErrorBoundary;
