import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { useRouter } from 'next/router';
import { UserType, WithConfig } from '@interfaces';

import { Box, Button, Image, Text } from '@ui/components';
import { useTheme } from '@ui/styles/hooks';
import { styled } from '@ui/styles';

import {
	CookieContextProvider,
	CookieContextConsumer,
} from '@common-lib/components/cookies/CookieContext';
import TermsModal from '@common-lib/modules/account/components/TermsModal';

const ContainerBox = styled(Box)(({ theme }) => ({
	'& .cookie-overlay': {
		[theme.breakpoints.up('sm')]: {
			position: 'absolute!important',
		},
	},
	'& .cookie-container': {
		alignItems: 'center!important',
		justifyContent: 'center!important',
		maxWidth: theme.breakpoints.values.sm,
		left: 'auto !important',
		[theme.breakpoints.up('sm')]: {
			position: 'absolute!important',
		},
		bottom: '67px !important',
	},
	'& > div': {
		// zIndex: '1150 !important',
		// [theme.breakpoints.up('sm')]: {
		// 	maxWidth: 390,
		// },
		maxWidth: theme.breakpoints.values.sm,
		left: 'auto !important',
	},
	'& .cookie-button-wrapper': {
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			marginBottom: 16,
			textAlign: 'center',
		},
		[theme.breakpoints.up('sm')]: {
			marginBottom: 8,
		},
		textAlign: 'center',
	},
}));

const LinkText = styled(Text)(({ theme }) => ({
	textDecoration: 'underline',
	cursor: 'pointer',
	fontFamily: theme.fontFamily.gilroy,
	fontSize: '0.8rem',
	lineHeight: '1.05rem',
	display: 'initial',
	fontWeight: '600',
}));

const PageCommon: React.FC<React.PropsWithChildren<WithConfig>> = ({
	children,
	config,
}) => {
	const { palette } = useTheme();
	const { pathname } = useRouter();
	const [open, setOpen] = React.useState<boolean>(false);
	const handleClick = React.useCallback(() => {
		setOpen(true);
	}, []);
	const handleClose = React.useCallback(() => {
		setOpen(false);
	}, []);
	const userType = pathname.includes('krass')
		? UserType.CONSUMENT
		: UserType.KROEG;
	return (
		<>
			<TermsModal
				userType={userType}
				modalTitle="Actievoorwaarden"
				open={open}
				hideButton
				onClose={handleClose}
			/>
			<CookieContextProvider cookieName="trackingAccept">
				{children}
				<CookieContextConsumer>
					{({ acceptCookies, declineCookies }) => (
						<ContainerBox>
							<CookieConsent
								location="bottom"
								buttonText="Accepteren"
								enableDeclineButton
								declineButtonText="Weigeren"
								cookieName="trackingAccept"
								containerClasses="cookie-container"
								buttonWrapperClasses="cookie-button-wrapper"
								overlayClasses="cookie-overlay"
								ButtonComponent={(props: {
									id: string;
									className: string;
									style: any;
									children: string;
									onClick: () => void;
									'aria-label': string;
								}) => {
									const { style, children, ...rest } = props;
									return (
										<Button
											variant={
												children === 'Accepteren'
													? 'contained'
													: 'text'
											}
											// @ts-ignore it exists
											color="secondary"
											// noSound
											sx={{
												m: 1,
												color:
													children !== 'Accepteren'
														? undefined
														: palette.secondary
																.contrastText,
											}}
											{...rest}
										>
											{children}
										</Button>
									);
								}}
								overlay={!pathname.startsWith('/privac')}
								style={{
									backgroundColor:
										config?.backgroundColor || '#f9bd3c',
								}}
								expires={999}
								onAccept={acceptCookies}
								onDecline={declineCookies}
							>
								<Box
									sx={{
										backgroundColor:
											config?.backgroundColor ||
											'#f9bd3c',
										border: `14px solid ${config?.backgroundColor || '#f9bd3c'}`,
										borderRadius: 100,
										position: 'absolute',
										top: -42,
										left: '50%',
										marginLeft: '-42px',
									}}
								>
									<Image
										priority
										alt="cookie"
										src="/icons/icon-cookie.png"
										width={60}
										height={58}
										style={{
											filter: `drop-shadow(0px 0px 3.5px #073b5b75)`,
										}}
									/>
								</Box>
								<Box zIndex={1} position="relative">
									<Text
										variant="h1"
										color="white"
										sx={{
											marginTop: 3,
											textAlign: 'center',
											fontSize: '1.2rem',
										}}
									>
										Deze site gebruikt cookies
									</Text>
								</Box>
								<Text
									p={1}
									variant="h2"
									fontWeight="regular"
									color="white"
									sx={{
										marginLeft: '7%',
										marginRight: '7%',
										fontSize: '0.8rem',
										lineHeight: '1.05rem',
										textAlign: {
											xs: 'center',
											sm: 'left',
										},
										fontWeight: '600',
									}}
									component="div"
								>
									Voor een optimale werking gebruikt deze site
									cookies. Door deze site te gebruiken, ga je
									akkoord met de gebruiksvoorwaarden.{' '}
									<LinkText onClick={handleClick}>
										lees meer
									</LinkText>
								</Text>
							</CookieConsent>
						</ContainerBox>
					)}
				</CookieContextConsumer>
			</CookieContextProvider>
		</>
	);
};

export default PageCommon;
