import React from 'react';
// import ReactGA from 'react-ga4';
import {
	GoogleTagManager,
	sendGTMEvent,
	GoogleAnalytics,
	sendGAEvent,
} from '@next/third-parties/google';
import { useRouter } from 'next/router';
// import { AppProps } from 'next/app';

import config from '@common-lib/clientConfig';
import CookieContext from '@common-lib/components/cookies/CookieContext';

// Multiple products (previously known as trackers)

// // Send pageview with a custom path
// ReactGA.send({ hitType: "pageview", page: "/my-path" });

// // Send a custom event
// ReactGA.event({
// category: "your category",
// action: "your action",
// label: "your label", // optional
// value: 99, // optional, must be a number
// nonInteraction: true, // optional, true/false
// transport: "xhr", // optional, beacon/xhr/image
// });

export const registerEvent = (name: string, action: string, value: string) => {
	sendGTMEvent({
		category: name,
		action: action,
		value: value,
	});
	sendGAEvent({
		category: name,
		action: action,
		value: value,
	});
};

let gaInit = false;

const GAListener: React.FC<{ isConsumer: boolean }> = ({ isConsumer }) => {
	const router = useRouter();
	const { allowCookies } = React.useContext(CookieContext);
	React.useEffect(() => {
		if (
			gaInit ||
			config.google.ga.test ||
			!allowCookies ||
			!config.google.ga.consumerId ||
			!config.google.ga.barId
		) {
			return;
		}
		gaInit = true;

		const handleRouteChange = (url: string) => {
			sendGTMEvent({
				hitType: 'pageview',
				page: url,
				streamId: isConsumer
					? config.google.ga.consumerStreamId
					: config.google.ga.barStreamId,
			});
			sendGAEvent({
				hitType: 'pageview',
				page: url,
				streamId: isConsumer
					? config.google.ga.consumerStreamId
					: config.google.ga.barStreamId,
			});
		};

		// initial hard reload only
		setTimeout(() => {
			sendGTMEvent({
				hitType: 'pageview',
				page: `${router.basePath}${router.asPath}`,
				streamId: isConsumer
					? config.google.ga.consumerStreamId
					: config.google.ga.barStreamId,
			});
			sendGAEvent({
				hitType: 'pageview',
				page: `${router.basePath}${router.asPath}`,
				streamId: isConsumer
					? config.google.ga.consumerStreamId
					: config.google.ga.barStreamId,
			});
		}, 300);

		router.events.on('routeChangeStart', handleRouteChange);

		// If the component is unmounted, unsubscribe
		// from the event with the `off` method:
		return () => {
			router.events.off('routeChangeStart', handleRouteChange);
		};
	}, [router.events, allowCookies, isConsumer]);
	if (config.google.ga.test || !allowCookies) {
		return null;
	}
	return (
		<>
			<GoogleTagManager
				gtmId={
					isConsumer
						? config.google.ga.consumerId
						: config.google.ga.barId
				}
			/>
			<GoogleAnalytics
				gaId={
					isConsumer
						? config.google.ga.consumerId
						: config.google.ga.barId
				}
			/>
		</>
	);
};

export default GAListener;
