import { setContext } from '@apollo/client/link/context';

import { getServerCookie, getCookie } from '@utils/helpers/cookie';

import { getItem } from '@common-lib/store';
import clientConfig from '@common-lib/clientConfig';

const { cookieName } = clientConfig;

const authLink = (isServer: boolean, opts: Record<string, any>) =>
	// eslint-disable-next-line
	setContext((_, req) => {
		const { headers = {} } = req;
		let token;
		if (isServer) {
			if (opts?.req?.user) {
				token = opts.req.user.token;
			} else {
				if (opts.req) {
					// try to get server cookie first
					token = getServerCookie(cookieName, opts?.req);
				}
				if (!token) {
					const auth = headers.authorization;
					token = auth && auth.replace('Bearer ', '').trim();
				}
			}
		} else if (typeof window !== 'undefined') {
			// web client
			token = getItem('token');
			if (!token) {
				// Try to get cookie if there is no local storage version
				token = getCookie(cookieName);
			}
		}
		const authorization = token ? { authorization: `Bearer ${token}` } : {};
		return {
			...req,
			headers: {
				...headers,
				...(opts?.req?.headers || {}),
				...authorization,
			},
		};
	});

export default authLink;
