import type { ISequelizeModel } from '@interfaces/model';
import { SequelizeModelKeys } from '@interfaces/model';

import { UserType } from './participant';

export enum PrizeType {
	AFAS = 'AFAS',
	APENHEUL = 'APENHEUL',
	BEEKSEBERGEN = 'BEEKSEBERGEN',
	BLIJDORP = 'BLIJDORP',
	DRIEVLIET = 'DRIEVLIET',
	DUINRELL = 'DUINRELL',
	KINEPOLIS = 'KINEPOLIS',
	MADURODAM = 'MADURODAM',
	OUWEHANDS = 'OUWEHANDS',
	THERMEN = 'THERMEN',

	SOLAR = 'SOLAR', // prize for bar owners
}

export interface IPrize extends ISequelizeModel {
	code: PrizeType;
	stock: number;
	userType: UserType;
	enabled: boolean;
}

export type PrizeCreationAttributes = Omit<IPrize, SequelizeModelKeys>;

export type IPrizePublic = IPrize;

export interface IPrizeInput {
	code: PrizeType;
	stock: number;
	userType: UserType;
	enabled: boolean;
}

export interface IPrizeCounter {
	id: string;
	current: number;
	isConsumer: boolean;
}

export type PrizeCounterCreationAttributes = Omit<IPrizeCounter, 'id'>;
