import React from 'react';
import Button from '@mui/material/Button';

import { ButtonProps as IProps } from './button';

export type ButtonProps = IProps;

var audio: HTMLAudioElement;
var audio2: HTMLAudioElement;

export type AudioRef = {
	audio: HTMLAudioElement;
	audio2: HTMLAudioElement;
};

const TheButton: React.ForwardRefRenderFunction<
	HTMLButtonElement,
	ButtonProps & {
		multiAudio?: boolean;
		audioRefs?: React.RefObject<AudioRef>;
	}
> = (
	{
		variant,
		size,
		onClick,
		noSound = false,
		ariaLabel,
		children,
		multiAudio,
		audioRefs,
		...props
	},
	ref,
) => {
	React.useImperativeHandle(audioRefs, () => ({ audio, audio2 }), []);
	React.useEffect(() => {
		if (!audioRefs && !noSound) {
			if (!audio) {
				audio = new Audio('/audio/click-button-2.mp4');
				audio.id = 'audio1';
			} else if (audio) {
				audio.src = '/audio/click-button-2.mp4';
			}
			if (multiAudio && !audio2) {
				audio2 = new Audio('/audio/click-button-2.mp4');
				audio2.id = 'audio2';
			} else if (audio2) {
				audio2.src = '/audio/click-button-2.mp4';
			}
		} else if (!noSound) {
			if (!audio) {
				audio = new Audio('/audio/click-button-2.mp4');
				audio.id = 'audio1';
			}
			if (multiAudio && !audio2) {
				audio2 = new Audio('/audio/click-button-2.mp4');
				audio2.id = 'audio2';
			}
		}
	}, [noSound, multiAudio, audioRefs]);
	const handleClick = React.useCallback(
		(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			if (!noSound) {
				audio.play().catch(err => console.log("Can't play audio", err));
				if (multiAudio && audio2) {
					audio2
						.play()
						.catch(err => console.log("Can't play audio", err));
				}
			}
			if (onClick) {
				onClick(e);
			}
		},
		[onClick, multiAudio],
	);
	return (
		<Button
			{...{
				...props,
				variant: variant || 'contained',
				size: size || 'large',
				ref,
			}}
			//@ts-ignore should work
			// aria-label={ariaLabel || (typeof children === 'string' && children)}
			onClick={handleClick}
		>
			{children}
		</Button>
	);
};
// TODO fix text variant color
export default React.forwardRef(TheButton);
