// import '@fontsource/libre-franklin';
// import '@fontsource/libre-franklin/300.css';
// import '@fontsource/libre-franklin/400.css';
// import '@fontsource/libre-franklin/500.css';
// import '@fontsource/libre-franklin/700.css';
// import '@ui/components/LightBox/style.css';

import { ApolloProvider } from '@apollo/client';
import { CacheProvider, EmotionCache } from '@emotion/react';
// import { LocalizationProvider } from '@mui/x-date-pickers';
// import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import localFont from 'next/font/local';
import { Session } from 'next-auth';
import { AppProps } from 'next/app';
import Head from 'next/head';
import React from 'react';
import { useRouter } from 'next/router';

// import SnackbarProvider from '@ui/components/SnackbarProvider';
import AppThemeProvider from '@ui/styles/components/AppThemeProvider';
// import AppGuard from '@web/components/AppGuard';
import { FontFamily } from '@ui/styles/types/fontFamily';

import { AppContextProvider } from '@common-lib/AppContext';
import clientConfig from '@common-lib/clientConfig';
import ErrorBoundary from '@common-lib/components/ErrorBoundary';
import GAListener from '@common-lib/components/ga/GAListener';
import { useApollo } from '@common-lib/graphql';
import { TermsContextProvider } from '@common-lib/modules/account/components/TermsContext';
import GeneralSnackbars from '@common-lib/modules/status/components/GeneralSnackbars';
import PageCommon from '@common-lib/PageCommon';
import { getBrandConfig } from '@common-lib/modules/landing/config';

import createEmotionCache from '@web/utils/createEmotionCache';
import { Page } from '@web/types';
import Container from '@web/components/Container';
import { MobileContextProvider } from '@web/components/MobileContext';
import AdminOnlySession from '@web/components/AdminOnlySession';
// import FacebookPixel from '@web/components/FacebookPixel';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

// TODO getServerSideProps is not supported here yet.
// We would want to use that to pass the mobile status to the PageContextProvider
interface MyAppProps extends Omit<AppProps, 'Component'> {
	emotionCache?: EmotionCache;
	session?: Session;
	isMobile: boolean;
	isConsumer?: boolean;
	Component: Page;
}

// export function reportWebVitals({
// 	id,
// 	name,
// 	label,
// 	value,
// }: NextWebVitalsMetric) {
// 	// Use `window.gtag` if you initialized Google Analytics as this example:
// 	// https://github.com/vercel/next.js/blob/canary/examples/with-google-analytics/pages/_app.js
// 	window.gtag('event', name, {
// 		event_category:
// 			label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
// 		value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
// 		event_label: id, // id unique to current page load
// 		non_interaction: true, // avoids affecting bounce rate.
// 	});
// }
const ceraCY = localFont({ src: '../fonts/Cera-CY.woff2' });
const gilroy = localFont({
	src: [
		// {
		// 	path: '../fonts/Gilroy-Black.woff2',
		// 	weight: '900',
		// 	style: 'normal',
		// },
		{
			path: '../fonts/Gilroy-Bold.woff2',
			weight: '700',
			style: 'bold',
		},
		{
			path: '../fonts/Gilroy-Extrabold.woff2',
			weight: '800',
			style: 'extrabold',
		},
		// {
		// 	path: '../fonts/Gilroy-Light.woff2',
		// 	weight: '300',
		// 	style: 'light',
		// },
		{
			path: '../fonts/Gilroy-Medium.woff2',
			weight: '500',
			style: 'medium',
		},
		{
			path: '../fonts/Gilroy-Regular.woff2',
			weight: '400',
			style: 'regular',
		},
		// {
		// 	path: '../fonts/Gilroy-Semibold.woff2',
		// 	weight: '600',
		// 	style: 'semibold',
		// },
	],
});
const fontFamily: FontFamily = {
	gilroy: gilroy.style.fontFamily,
	ceraCY: ceraCY.style.fontFamily,
};

const triggerScroll = () => {
	if (typeof window !== 'undefined') {
		window.scrollTo(0, 1);
	}
};
const fullScreen = () => {
	if (typeof window !== 'undefined') {
		try {
			const elem: any = document.documentElement;
			if (elem.requestFullscreen) {
				elem.requestFullscreen();
			} else if (elem.webkitRequestFullscreen) {
				/* Safari */
				elem.webkitRequestFullscreen();
			} else if (elem.msRequestFullscreen) {
				/* IE11 */
				elem.msRequestFullscreen();
			} else if (elem.mozRequestFullScreen) {
				/* IE11 */
				elem.mozRequestFullScreen();
			}
		} catch (err) {
			console.error(err);
		}
	}
};
const MyApp: React.FC<MyAppProps> = ({
	Component,
	emotionCache = clientSideEmotionCache,
	isMobile = true,
	isConsumer: consumer,
	pageProps: { session, config, ...pageProps },
}) => {
	const { isReady, pathname } = useRouter();
	const client = useApollo({
		session,
		...pageProps,
		config,
	});
	const isConsumer = consumer || !pathname.includes('verras');
	React.useEffect(() => {
		if (isReady) {
			setTimeout(triggerScroll, 100);
		}
	}, [isReady]);
	const { favicon, favicon32, maskIcon, touchIcon, androidIcon, manifest } =
		getBrandConfig(config?.brand);
	// React.useEffect(() => {
	// 	if (typeof window !== 'undefined') {
	// 		// const scrollContainer = document.querySelector('main > div');
	// 		// if (scrollContainer) {
	// 		// 	scrollContainer.addEventListener('scroll', function () {
	// 		// 		// Get the current scroll position of the div
	// 		// 		const scrollTop = scrollContainer.scrollTop;
	// 		// 		console.log('scrollContainer');

	// 		// 		// Show or hide the location bar and status bar based on the scroll position
	// 		// 		if (scrollTop > 0) {
	// 		// 			// Scroll down, hide the bars
	// 		// 			window.scrollTo(0, 1); // Hides location bar on iOS Safari
	// 		// 			document.body.style.overflow = 'hidden'; // Prevents bounce effect
	// 		// 		} else {
	// 		// 			// At the top, show the bars
	// 		// 			window.scrollTo(0, 0); // Shows location bar on iOS Safari
	// 		// 			document.body.style.overflow = 'auto'; // Restore default overflow behavior
	// 		// 		}
	// 		// 	});
	// 		// }

	// 		const isMobile =
	// 			(navigator as any).userAgentData?.mobile ||
	// 			/iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
	// 		const doc = document as any;
	// 		const isInFullScreen =
	// 			(doc.fullScreenElement && doc.fullScreenElement !== null) || // alternative standard method
	// 			doc.mozFullScreen ||
	// 			doc.webkitIsFullScreen;
	// 		if (!isInFullScreen && isMobile) {
	// 			window.addEventListener('touchstart', fullScreen);
	// 		}
	// 	}
	// }, []);
	return (
		<MobileContextProvider isMobile={isMobile}>
			<CacheProvider value={emotionCache}>
				<Head>
					<meta charSet="utf-8" />
					<meta
						name="viewport"
						content="minimal-ui, width=device-width, initial-scale=1, viewport-fit=cover"
					/>
					<meta name="apple-mobile-web-app-capable" content="yes" />
					<meta name="mobile-web-app-capable" content="yes" />
					<meta name="apple-mobile-web-app-title" content="Vrumona" />
					<meta name="apple-mobile-web-app-capable" content="yes" />
					<meta
						name="apple-mobile-web-app-status-bar-style"
						content="black-translucent"
					/>
					{/* PWA primary color */}
					<meta
						name="theme-color"
						content={config?.backgroundColor || '#f9bd3c'}
					/>
					<meta
						name="description"
						content="Scan QR-codes van Russel & Co. Rose Lemonade, Royal Club Ginger Beer, Rivella Original, Rivella Cranberry of Ranja Fruitmix en maak kans op leuke uitjes"
					/>
					{/* <link
						rel="stylesheet"
						href="https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=optional"
					/> */}
					{/* <link
						rel="stylesheet"
						href="https://fonts.googleapis.com/css?family=Libre+Franklin:200,400,600,700,800,900&display=swap"
					/> */}
					<link rel="shortcut icon" href={favicon} />
					<link
						id="favicon"
						rel="shortcut icon"
						type="image/x-icon"
						href={favicon}
					/>
					<link
						id="favicon32"
						rel="icon"
						type="image/png"
						sizes="32x32"
						href={favicon32}
					/>
					<link rel="manifest" href={manifest} />
					<link
						rel="mask-icon"
						href={maskIcon}
						color={config?.backgroundColor || '#F1BC62'}
					/>
					<link
						rel="apple-touch-icon"
						sizes="180x180"
						href={touchIcon}
					/>
					<link
						rel="icon"
						type="image/png"
						sizes="192x192"
						href={androidIcon}
					/>
				</Head>
				{/* <FacebookPixel /> */}
				<AdminOnlySession session={session} Component={Component}>
					{/* <AppGuard> */}
					<AppContextProvider value={clientConfig}>
						<ApolloProvider client={client}>
							<AppThemeProvider fontFamily={fontFamily}>
								<Container fixed maxWidth="xs">
									<ErrorBoundary>
										{/* <SnackbarProvider> */}
										{/* <LocalizationProvider
												dateAdapter={AdapterLuxon}
												adapterLocale="pt-Br"
											> */}
										<PageCommon config={config}>
											<>
												<TermsContextProvider>
													<Component
														{...pageProps}
														config={config}
													/>
												</TermsContextProvider>
												<GAListener
													isConsumer={isConsumer}
												/>
											</>
										</PageCommon>
										{/* </LocalizationProvider> */}

										<GeneralSnackbars />
										{/* </SnackbarProvider> */}
									</ErrorBoundary>
								</Container>
							</AppThemeProvider>
						</ApolloProvider>
					</AppContextProvider>
					{/* </AppGuard> */}
				</AdminOnlySession>
			</CacheProvider>
		</MobileContextProvider>
	);
};

export default MyApp;
