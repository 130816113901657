import React from 'react';
import MuiSnackbar from '@mui/material/Snackbar';
import Alert, { AlertProps } from '@mui/material/Alert';

import { styled } from '@ui/styles';

import { SnackbarProps as IProps } from './snackbar';

export type SnackbarProps = IProps;

const Snackbar = styled((props: Omit<SnackbarProps, 'ref'>) => (
	<MuiSnackbar {...props} />
))(({ theme }) => ({
	'& > div': {
		width: theme.breakpoints.down('xs') ? '100%' : 'auto',
		minWidth: theme.breakpoints.down('xs') ? '100%' : 'auto',
		maxWidth: theme.breakpoints.down('xs') ? 600 : 'auto',
		display: 'flex',
		flexDirection: 'row-reverse',
		justifyContent: 'space-between',
	},
	'& .MuiAlert-icon': {
		marginLeft: theme.spacing(1.5),
		marginRight: 0,
	},
}));

const TheSnackbar: React.FC<
	SnackbarProps & { alertProps: Omit<AlertProps, 'children'> }
> = ({ alertProps, message, children, ...props }) => {
	return (
		<Snackbar {...props}>
			<Alert
				{...alertProps}
				elevation={alertProps.elevation || 6}
				variant={alertProps.variant || 'filled'}
			>
				{children || message}
			</Alert>
		</Snackbar>
	);
};

export default TheSnackbar;
