import { useRouter } from 'next/router';
import { SessionProvider, SessionProviderProps } from 'next-auth/react';

import { Page } from '@web/types';
import AuthGuard from '@web/components/AuthGuard';

const AdminOnlySession: React.FC<
	SessionProviderProps & {
		Component: Page;
	}
> = ({ Component, children, ...props }) => {
	const { pathname } = useRouter();
	if (
		pathname.includes('admin') ||
		pathname.includes('auth') ||
		pathname.includes('account')
	) {
		return (
			<SessionProvider {...props}>
				<AuthGuard
					requireAuth={Component.requireAuth}
					redirect={Component.redirect}
					requireRoles={Component.requireRoles}
				>
					{children}
				</AuthGuard>
			</SessionProvider>
		);
	}
	return <>{children}</>;
};

export default AdminOnlySession;
