import type { ISequelizeModel } from '@interfaces/model';
import { SequelizeModelKeys } from '@interfaces/model';

import { IQRCodeSequelize } from './qrCode';
import { IPrize } from './prize';

export enum UserType {
	CONSUMENT = 'CONSUMENT',
	KROEG = 'KROEG',
}

export interface IParticipant extends ISequelizeModel {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
	emailAlt: string;
	phone: string | null;
	acceptTerms: boolean;
	keepInformed: boolean;
	qrCode: IQRCodeSequelize;
	userType: UserType;
	ipAddress?: string | null;
	prizeId: string | null;
	prize: IPrize | null;
}

export interface Consumer extends IParticipant {
	adult: boolean;
	city: string | null;
	terras: string | null;
}

export interface BarOwner extends IParticipant {
	company: string;
	zipCode: string;
	houseNumber: number;
	houseNumberExtra?: string | null;
}

export type ParticipantCreationAttributes = Omit<
	IParticipant,
	SequelizeModelKeys | 'qrCode' | 'company' | 'zipCode' | 'houseNumber'
> & {
	qrCodeId?: string;

	adult?: boolean;
	company?: string;
	zipCode?: string;
	houseNumber?: number;
	houseNumberExtra?: string | null;
};

export type IParticipantPublic = IParticipant;

export interface IParticipantInput {
	firstName: string;
	lastName: string;
	email: string;
	phone: string | null;
	acceptTerms: boolean;
	keepInformed: boolean;
}

export interface IConsumerInput extends IParticipantInput {
	firstName: string;
	lastName: string;
	email: string;
	phone: string | null;
	acceptTerms: boolean;
	keepInformed: boolean;
	adult: boolean;
	city: string;
	terras: string;

	qrCodeId: string;
}

export interface IBarOwnerInput extends IParticipantInput {
	company: string;
	zipCode: string;
	houseNumber: number;
	houseNumberExtra?: string;
	firstName: string;
	lastName: string;
	email: string;
	phone: string | null;
	acceptTerms: boolean;
	keepInformed: boolean;
}

export interface IParticipantBatch extends ISequelizeModel {
	id: string;
	success: boolean;
	result: string | null;
	dteSent: Date;
}

export type ParticipantBatchCreationAttributes = Omit<
	IParticipantBatch,
	SequelizeModelKeys | 'success' | 'result'
>;

export interface IParticipantBlock extends ISequelizeModel {
	id: string;
	email: string;
	phone: string | null;
	qrCodes: IParticipantBlockQRCode[];
}

export type ParticipantBlockCreationAttributes = Omit<
	IParticipantBlock,
	SequelizeModelKeys | 'qrCodes'
> & {
	qrCodes: {
		qrCodeId: string;
	}[];
};

export interface IParticipantBlockInput {
	email: string;
	phone?: string | null;
}

export interface IParticipantBlockQRCode extends ISequelizeModel {
	id: string;
	qrCodeId: string;
	qrCode: IQRCodeSequelize;
	participantBlockId: string;
	participantBlock: IParticipantBlock;
}

export type ParticipantBlockQRCodeCreationAttributes = Omit<
	IParticipantBlockQRCode,
	SequelizeModelKeys | 'participanBlock' | 'qrCode'
> & { participantBlockId: string; qrCodeId: string };
