import { Brand } from '@interfaces/brand';

export const getBrandConfig = (brand?: Brand) => {
	switch (brand) {
		case Brand.RANJA:
			return {
				favicon: '/ranja/favicon.ico',
				favicon32: '/ranja/favicon-32x32.png',
				maskIcon: '/ranja/safari-pinned-tab.svg',
				touchIcon: '/ranja/apple-touch-icon.png',
				androidIcon: '/ranja/android-chrome-192x192.png',
				manifest: '/ranja/manifest.json',
			};
		case Brand.RIVELLA:
			return {
				favicon: '/rivella/favicon.ico',
				favicon32: '/rivella/favicon-32x32.png',
				mask: '/rivella/safari-pinned-tab.svg',
				touchIcon: '/rivella/apple-touch-icon.png',
				androidIcon: '/royalclub/android-chrome-192x192.png',
				manifest: '/royalclub/manifest.json',
			};
		case Brand.ROYALCLUB:
			return {
				favicon: '/royalclub/favicon.ico',
				favicon32: '/royalclub/favicon-32x32.png',
				mask: '/royalclub/safari-pinned-tab.svg',
				touchIcon: '/royalclub/apple-touch-icon.png',
				androidIcon: '/royalclub/android-chrome-192x192.png',
				manifest: '/royalclub/manifest.json',
			};
		case Brand.RUSSELCO:
			return {
				favicon: '/favicon.ico',
				favicon32: '/favicon-32x32.png',
				mask: '/safari-pinned-tab.svg',
				touchIcon: '/apple-touch-icon.png',
				androidIcon: '/android-chrome-192x192.png',
				manifest: '/manifest.json',
			};
		default:
			return {
				favicon: '/favicon.ico',
				favicon32: '/favicon-32x32.png',
				mask: '/safari-pinned-tab.svg',
				touchIcon: '/apple-touch-icon.png',
				androidIcon: '/android-chrome-192x192.png',
				manifest: '/manifest.json',
			};
	}
};
