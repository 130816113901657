import React from 'react';
import Grid, { GridProps } from '@mui/material/Grid';

export type RowProps = Omit<GridProps, 'container' | 'item'>;

const Row: React.FC<RowProps> = props => (
	<Grid container direction={props.direction || 'row'} {...props} />
);

export default Row;
