import React from 'react';

import { useTheme } from '@ui/styles/hooks';
import useMediaQuery from '@ui/hooks/useMediaQuery';

type PageProps = {
	isMobile: boolean;
};

const MobileContext = React.createContext<boolean | undefined>(undefined);

const MobileContextProvider: React.FC<React.PropsWithChildren<PageProps>> = ({
	children,
	isMobile: ssrMobile, // this becomes undifined on cleint side
}) => {
	const theme = useTheme();
	const clientMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const isMobile = ssrMobile || clientMobile;
	// const isMobile = undefined;
	const [mobile, setMobile] = React.useState<boolean | undefined>(false);
	// The idea is to only set this on first page load
	// on subsequent page transitions, it will be undefined
	// we could alternatively also read it client side.
	React.useEffect(() => {
		if (mobile !== undefined && isMobile !== undefined) {
			setMobile(isMobile);
		}
	}, [isMobile, mobile]);
	return (
		<MobileContext.Provider value={mobile}>
			{children}
		</MobileContext.Provider>
	);
};

export { MobileContext as default, MobileContextProvider };
