import React from 'react';
import { animated, useSpring } from '@react-spring/web';

import { Box, Text, Image, Link, Button } from '@ui/components';
import { styled } from '@ui/styles';
import { BoxProps } from '@ui/components/Box';

import TopCurveBox from '@common-lib/modules/landing/components/TopCurveBox';

const AnimatedBox = styled(animated.div)(({ theme }) => ({
	textAlign: 'center',
	paddingTop: theme.spacing(2),
	paddingBottom: theme.spacing(1),
	display: 'flex',
	flexDirection: 'column',
	flex: '1 1 auto',
	justifyContent: 'center',
	backgroundImage: `url('/images/background4.webp')`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
	borderRadius: 32,
	backgroundPosition: 'top',
	margin: 14,
	// iphone only
	// '@supports (-webkit-touch-callout: none)': {
	// 	paddingTop: theme.spacing(3),
	// 	flex: '0 0 auto',
	// },
}));

const Wrapper = styled(Box)(({ theme }) => ({
	textAlign: 'center',
	marginLeft: '8%',
	marginRight: '8%',
	marginTop: theme.spacing(-13),
	display: 'flex',
	flexDirection: 'column',
	flex: '1 1 auto',
}));
const TheText = styled(Text)(({ theme }) => ({
	fontSize: '1.8125rem',
	lineHeight: '1.625rem',
}));
const TheButton = styled(Button)(({ theme }) => ({
	fontSize: '0.9375rem',
	lineHeight: '0.5rem',
}));

const ImageWrapper = styled(TopCurveBox)(({ theme }) => ({
	marginTop: theme.spacing(-3),
	'&& > div': {
		marginRight: 12,
		marginLeft: -9,
	},
	'&& img': {
		width: '100%',
		height: '100%',
		aspectRatio: 'auto 4 / 5',
	},
}));

type Props = {
	hash: string;
	overlay?: boolean;
	iconWidth?: string | number;
	iconHeight?: string | number;
	onReset: () => void;
} & Omit<BoxProps, 'ref'>;

const Error400: React.FC<Props> = ({
	overlay,
	iconWidth,
	iconHeight,
	hash,
	children,
	onReset,
	...rest
}) => {
	const props = useSpring({
		from: { opacity: 0, marginTop: 200 },
		to: { opacity: 1, marginTop: 14 },
		delay: 900,
	});

	const imageBoxProps = useSpring({
		from: { display: 'none' },
		to: { display: 'block' },
		delay: 800,
	});
	return (
		<AnimatedBox
			style={{ ...props, zIndex: overlay ? 10 : undefined }}
			className="wrapper-box bt-margin"
		>
			<ImageWrapper>
				<animated.div style={imageBoxProps}>
					<Image
						priority
						alt="keyvisual-consumer"
						src="/images/keyvisual-btc-ranja.webp"
						width={375}
						height={548}
					/>
				</animated.div>
			</ImageWrapper>
			<Wrapper>
				{children || (
					<Box mt={2} textAlign="center">
						<Text
							color="warning.main"
							variant="body1"
							fontWeight="bold"
						>
							Er is iets foutgegaan.
						</Text>
						<Text variant="body1" fontWeight="medium" gutterBottom>
							Identificador do erro: {hash}
						</Text>
						<Button
							variant="contained"
							color="secondary"
							onClick={onReset}
						>
							Probeer te herladen
						</Button>
					</Box>
				)}
			</Wrapper>
		</AnimatedBox>
	);
};

export default Error400;
