import React from 'react';

import CircularProgress, {
	circularProgressClasses,
	CircularProgressProps,
} from '@ui/components/CircularProgress';
import Box from '@ui/components/Box';
import { useTheme } from '@ui/styles/hooks';

const Loading: React.FC<CircularProgressProps> = ({ sx, ...props }) => {
	const theme = useTheme();
	return (
		<Box sx={{ position: 'relative', ...sx }}>
			<CircularProgress
				variant="determinate"
				sx={{
					color: theme.palette.grey[
						theme.palette.mode === 'light' ? 200 : 800
					],
				}}
				size={40}
				thickness={4}
				{...props}
				value={100}
			/>
			<CircularProgress
				variant="indeterminate"
				disableShrink
				sx={{
					color:
						theme.palette.mode === 'light'
							? 'primary'
							: 'secondary',
					animationDuration: '550ms',
					position: 'absolute',
					left: 0,
					[`& .${circularProgressClasses.circle}`]: {
						strokeLinecap: 'round',
					},
				}}
				size={40}
				thickness={4}
				{...props}
			/>
		</Box>
	);
};

export default Loading;
