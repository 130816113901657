import { BreakpointsOptions } from '@mui/material/styles';

/**
 * Default grid
 * https://material-ui.com/customization/default-theme/
 */
export const grid: BreakpointsOptions = {
	values: {
		// xs: 0,
		// sm: 600,
		// md: 900,
		// lg: 1200,
		// xl: 1536,

		xs: 0,
		sm: 444,
		md: 600,
		lg: 900,
		xl: 1200,
	},
};

export default grid;
