import type { ISequelizeModel } from '@interfaces/model';
import { SequelizeModelKeys } from '@interfaces/model';

import { IParticipant } from './participant';

export enum QRCodeStatus {
	EXISTS = 'EXISTS',
	NOTFOUND = 'NOTFOUND',
}
export interface IQRCode extends ISequelizeModel {
	qrCode: string;
	participants: IParticipant[];
	enabled: boolean;
}

export type IQRCodeSequelize = Omit<IQRCode, 'hasWinner'>;

export type QRCodeCreationAttributes = Omit<
	IQRCode,
	SequelizeModelKeys | 'participants'
>;

export interface IQRCodeInput {
	qrCode: string;
	enabled: boolean;
	isConsumer: boolean;
}
