import React from 'react';

import Box, { BoxProps } from '@ui/components/Box';
import LoadingIndicator from '@ui/components/Loading';
import { styled } from '@ui/styles';

type Props = {
	overlay?: boolean;
} & Omit<BoxProps, 'ref'>;

const Wrapper = styled(Box)(({ theme }) => ({
	alignItems: 'center',
	paddingTop: theme.spacing(3),
	paddingBottom: theme.spacing(3),
	display: 'flex',
	flexDirection: 'column',
	flex: '1 1 auto',
	justifyContent: 'center',
	width: '100%',
	height: '100%',
}));

const Loading: React.FC<Props> = ({ overlay, children, ...props }) => {
	return (
		<Wrapper
			{...props}
			zIndex={overlay ? 10 : undefined}
			position={overlay ? 'absolute' : undefined}
		>
			<LoadingIndicator />
			{children}
		</Wrapper>
	);
};

export default Loading;
