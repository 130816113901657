import React from 'react';
import Modal, { ModalProps } from '@mui/material/Modal';
import { UserType } from '@interfaces';

import Box from '@ui/components/Box';
import Text from '@ui/components/Text';
import { styled } from '@ui/styles';
import Button from '@ui/components/Button';
import IconButton from '@ui/components/IconButton';
import CancelIcon from '@ui/icons/Cancel';
import Row from '@ui/components/Grid/Row';

import MobileContext from '@common-lib/components/layout/MobileContext';

import Terms from './Terms';

type TermsModalProps = {
	userType: UserType;
	modalTitle?: string;
	bgColor?: string;
	hideButton?: boolean;
	onAccept?: () => void;
} & Omit<ModalProps, 'ref' | 'children'>;

const BoxPaper = styled(Box, {
	shouldForwardProp: prop => prop !== 'isMobile',
})<{ isMobile?: boolean }>(({ theme, isMobile }) => ({
	backgroundColor: theme.palette.background.paper,
	maxWidth: theme.breakpoints.values.sm,
	width: '100%',
	...(isMobile
		? {
				position: 'fixed',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
			}
		: {
				position: 'absolute',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
			}),
	// height: isMobile ? '100vh' : '95vh',
	maxHeight: '100%',
	'& .terms': {
		paddingBottom: theme.spacing(15),
	},
}));
const ModalWrapper = styled(Modal)(({ theme }) => ({}));
const TheBox = styled(Box, { shouldForwardProp: prop => prop !== 'bgColor' })<{
	bgColor?: string;
}>(({ theme, bgColor }) => ({
	padding: 10,
	backgroundColor: bgColor || theme.palette.primary.dark,
}));
const TermsModal: React.FC<TermsModalProps> = ({
	userType,
	open,
	onClose,
	hideButton,
	onAccept,
	modalTitle = 'Algemene voorwaarden en Privacy',
	bgColor,
	...rest
}) => {
	const isMobile = React.useContext(MobileContext);
	const handleClose = React.useCallback(() => {
		if (onClose) {
			onClose({}, 'backdropClick');
		}
	}, [onClose]);
	const maxHeight = isMobile
		? `${hideButton ? 90 : 70}% !important`
		: undefined;
	return (
		<ModalWrapper
			open={open}
			onClose={onClose}
			{...rest}
			aria-labelledby="modal-title"
			aria-describedby="modal-description"
		>
			<BoxPaper isMobile={isMobile} className="terms-wrapper">
				<Row
					justifyContent="space-between"
					alignItems="center"
					px={2}
					py={1}
				>
					<Text
						// gutterBottom
						id="modal-title"
						variant="h2"
						color="secondary"
						fontWeight="bold"
					>
						{modalTitle}
					</Text>
					<Box mr={-2}>
						<IconButton onClick={handleClose} size="small">
							<CancelIcon />
						</IconButton>
					</Box>
				</Row>
				<TheBox bgColor={bgColor}>
					<Terms
						className="terms"
						hideButton={hideButton}
						userType={userType}
						maxHeight={maxHeight}
					/>
				</TheBox>
				{/*!scroll && !hideButton ? (
					<Text
						sx={{ mt: { xs: 1, sm: 2 }, mb: 1 }}
						gutterBottom
						color="error"
						align="center"
					>
						Role até o fim para habilitar o botão
					</Text>
				) : null}
				{hideButton ? null : (
					<Button
						disabled={!scroll}
						onClick={onAccept}
						fullWidth
						sx={{
							mb: 2,
							mt: {
								xs: 2,
								sm: 3,
							},
						}}
					>
						Accepteren
					</Button>
				)*/}
				{hideButton ? null : (
					<Box px={2}>
						<Button
							color="secondary"
							onClick={onAccept}
							fullWidth
							sx={{
								mb: 2,
								mt: {
									xs: 2,
									sm: 3,
								},
							}}
							noSound
						>
							Accepteren
						</Button>
					</Box>
				)}
			</BoxPaper>
		</ModalWrapper>
	);
};

export default TermsModal;
