import React from 'react';
import { UserType } from '@interfaces';

import Box, { BoxProps } from '@ui/components/Box';
import { styled } from '@ui/styles';
import Button from '@ui/components/Button';

import Loading from '@common-lib/components/Loading';

const TermsContentConsumer = React.lazy(() => import('./TermsContentConsumer'));
const TermsContentBarOwner = React.lazy(() => import('./TermsContentBarOwner'));

const ScrollWrapper = styled(Box, {
	shouldForwardProp: prop => prop !== 'hideButton',
})<{ hideButton: boolean }>(({ theme, hideButton }) => ({
	overflowY: 'auto',
	backgroundImage: `url('/images/background2.webp')`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
	height: hideButton ? 'calc(100vh - 135px)' : 'calc(100vh - 170px)',
	borderRadius: 32,
	'&::-webkit-scrollbar': {
		width: 8,
		borderRadius: 4,
	},
	'&::-webkit-scrollbar-track': {
		background: '#F0F0F0',
		width: 4,
	},
	'&::-webkit-scrollbar-thumb': {
		background: '#A2AABD',
		width: 8,
		borderRadius: 4,
	},
	'&::-webkit-scrollbar-thumb:hover': {
		background: '#70778a',
	},
	'&:hover': {
		'&::-webkit-scrollbar-thumb': {
			background: '#70778a',
		},
	},
	// [theme.breakpoints.down('xs')]: {
	// 	padding: '0 0.5rem 0',
	// 	margin: '0.5rem 0.5rem 0',
	// 	'& p': {
	// 		fontSize: '14px',
	// 	},
	// },
}));

type TermProps = {
	hideButton?: boolean;
	bgColor?: string;
	userType: UserType;
	onScrollEnd?: (end: boolean) => void;
} & Omit<BoxProps, 'ref'>;

const Term: React.FC<TermProps> = ({
	hideButton = false,
	onScrollEnd,
	userType,
	...rest
}) => {
	const handleScroll = React.useCallback(
		(e: React.UIEvent<HTMLDivElement>) => {
			const { currentTarget: target } = e;
			if (
				target.scrollHeight - Math.abs(target.scrollTop) - 10 <=
				target.clientHeight
			) {
				if (onScrollEnd) {
					onScrollEnd(true);
				}
			}
		},
		[onScrollEnd],
	);

	return (
		<ScrollWrapper
			hideButton={hideButton}
			display="flex"
			flexDirection="column"
			onScroll={handleScroll}
			{...rest}
		>
			{userType === UserType.CONSUMENT ? (
				<React.Suspense fallback={<Loading />}>
					<TermsContentConsumer isModal />
				</React.Suspense>
			) : (
				<React.Suspense fallback={<Loading />}>
					<TermsContentBarOwner isModal />
				</React.Suspense>
			)}
		</ScrollWrapper>
	);
};

type TermsProps = {
	hideButton?: boolean;
	userType: UserType;
	onAccept: () => void;
} & Omit<BoxProps, 'ref'>;

const TermsAccept: React.FC<TermsProps> = ({
	hideButton = false,
	userType,
	onAccept,
	...rest
}) => {
	const [scrollEnd, setScrollEnd] = React.useState(false);
	const handleScrollEnd = React.useCallback((end: boolean) => {
		setScrollEnd(end);
	}, []);
	const handleAccept = React.useCallback(() => {
		onAccept();
	}, [scrollEnd, onAccept]);
	return (
		<Box {...rest}>
			<Term
				hideButton={hideButton}
				userType={userType}
				onScrollEnd={handleScrollEnd}
			/>
			<Box
				pt={2}
				display="flex"
				flexDirection="row"
				justifyContent="center"
			>
				<Button
					color="secondary"
					fullWidth
					sx={{ maxWidth: 400 }}
					disabled={!scrollEnd}
					onClick={handleAccept}
				>
					Accepteren
				</Button>
			</Box>
		</Box>
	);
};
export { Term as default, TermsAccept };
