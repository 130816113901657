import { ISequelizeModel, SequelizeModelKeys } from './model';

export enum Role {
	USER = 'USER',
	ADMIN = 'ADMIN',
}

export enum Gender {
	M = 'M',
	F = 'F',
	U = 'U',
}

export interface IUser extends ISequelizeModel {
	identificationId?: string | null;
	firstName?: string;
	lastName?: string;
	image?: string;
	name?: string;
	password?: string;
	email?: string;
	phoneNumber?: string;
	gender?: Gender;
	status?: boolean;
	blocked?: boolean;
	dteBlocked?: Date | null;
	enabled?: boolean;
	emailVerified?: Date | null;
	phoneVerified?: Date | null;
	dteEnabled?: Date | null;
	lastloggedIn?: Date | null;
	isAcceptedTerms?: boolean;
	skipPhoneVerification?: boolean;
	defaultRole: Role;
}

export interface IUserPublic
	extends Omit<IUser, 'identificationId' | 'searchText'> {}

export interface IViewer {
	expiry?: Date | null;
	// TODO should this be IUserPublic?
	user?: IUser | null;
}

export type UserCreationAttributes = Omit<
	IUser,
	| SequelizeModelKeys
	| 'emailVerified'
	| 'phoneVerified'
	| 'blocked'
	| 'password'
	| 'defaultRole'
> & {
	blocked?: boolean;
	emailVerified?: Date | null;
	phoneVerified?: Date | null;
	defaultRole?: Role;
};

export interface IPhotoInput {
	label: string;
	name: string;
	folder: string;
}
export interface IUserDetailInput {
	firstName: string;
	lastName: string;
}
export interface IUserInput {
	firstName: string;
	lastName: string;
	gender: Gender;
}

export interface IAdminUserInput {
	firstName: string;
	lastName: string;
	gender: Gender;
	defaultRole: Role;
}

export interface ITermsContext {
	accepted?: boolean;
	acceptTerms: () => void;
	rejectTerms: () => void;
}

export type TermsType = 'user';
