import React from 'react';
import { useQuery, useMutation } from '@apollo/client';

import Snackbar from '@ui/components/Snackbar';
import Portal from '@ui/components/Portal';
import { AlertColor } from '@ui/components/Alert';
import OkIcon from '@ui/icons/CheckCircleInvert';
import ErrorIcon from '@ui/icons/ErrorCircle';
import { styled } from '@ui/styles';
import AlertIcon from '@ui/icons/InfoCircle';

import {
	QUERY_STATUS,
	MUTATION_RESET_STATUS,
} from '@common-lib/modules/status/graphql';
import { StatusType } from '@common-lib/modules/status/config';

import ConnectionSnackbar from './ConnectionSnackbar';

const AUTOHIDE = 3000;

const TheSnackbar = styled(Snackbar)(({ theme }) => ({
	top: theme.spacing(1),
}));

const GeneralSnackbar = () => {
	const [visible, setVisible] = React.useState(false);
	const statusData = useQuery(QUERY_STATUS);
	const [clearErrors] = useMutation(MUTATION_RESET_STATUS);
	const currentStatus = statusData.data?.local__status;
	const handleClear = React.useCallback(() => {
		clearErrors();
	}, [clearErrors]);
	React.useEffect(() => {
		if (currentStatus) {
			setVisible(true);
		} else {
			setVisible(false);
			clearErrors();
		}
	}, [currentStatus, clearErrors]);
	let message: string | null = null;
	let icon: JSX.Element | null = null;
	let type: AlertColor = 'warning';
	// Workaround for bug in lingui
	if (currentStatus) {
		message = currentStatus.message;
		switch (currentStatus.type) {
			case StatusType.OK:
				icon = <OkIcon color="success" width={22} height={22} />;
				type = 'success';
				message = currentStatus.message || `Sucesso!`;
				break;
			case StatusType.ERROR:
				icon = <ErrorIcon color="error" width={22} height={22} />;
				type = 'error';
				message = currentStatus.message || `Alguma coisa deu errado.`;
				break;
			case StatusType.VALIDATIONERROR:
				icon = <ErrorIcon color="error" width={22} height={22} />;
				type = 'error';
				message =
					currentStatus.message || `Favor revise seu formulário.`;
				break;
			case StatusType.SERVERDOWN:
				icon = <ErrorIcon color="error" width={22} height={22} />;
				type = 'error';
				message = currentStatus.message || `Servidor offline.`;
				break;
			case StatusType.INFO:
				icon = <AlertIcon color="warning" width={22} height={22} />;
				type = 'warning';
				message = currentStatus.message;
				break;
			default:
				throw new Error(`Wrong status type ${type}`);
		}
	}
	return (
		<Portal>
			<TheSnackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={visible && !!message} // make invisible if no message
				autoHideDuration={
					currentStatus?.type === StatusType.SERVERDOWN
						? AUTOHIDE * 2
						: AUTOHIDE
				}
				onClose={handleClear}
				alertProps={{
					icon,
					severity: type,
				}}
				message={message || ''} // add empty string bc it's required
			/>
			<ConnectionSnackbar />
		</Portal>
	);
};

export default GeneralSnackbar;
