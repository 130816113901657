import { UrlObject } from 'url';

export * from './account';
export * from './cookie';
export * from './cron';
export * from './enum';
export * from './faq';
export * from './filter';
export * from './graphql-common';
export * from './logo';
export * from './model';
export * from './nextPage';
export * from './participant';
export * from './prize';
export * from './qrCode';
export * from './session';
export * from './upload';
export * from './user';
export * from './verificationToken';
export * from './brand';

export interface MergeOption {
	reverse?: boolean;
	concat?: boolean;
	preventInfiniteKeys: string[];
}

export interface IAppContext {
	host?: string;
	s3Proxy: string;
	graphqlUrl?: string;
	graphqlWSUrl?: string;
	appName?: string;
	googleMapsApiKey: string;
	darkMode: boolean;
	toggleTheme: () => void;
	language: string | 'pt-br' | 'en-us';
	// [key: string]: any;
}

export type WithLocation<T = any> = {
	location: { lat: number; lng: number };
} & T;

export type WithConfig<T = any> = {
	config: {
		keyvisual: string;
		keyvisual2: string;
		product: string;
		backgroundColor: string;
		brand: string;
	};
} & T;

export type Url = UrlObject | string;
export interface TransitionOptions {
	shallow?: boolean;
	locale?: string | false;
	scroll?: boolean;
}

export type RouterPush = (
	url: Url,
	as?: Url,
	options?: TransitionOptions,
) => Promise<boolean> | void;

export type RouterReplace = (
	url: Url,
	as?: Url,
	options?: TransitionOptions,
) => Promise<boolean> | void;

export type ModuleConfig = {
	frontEndUrl: string;
	[key: string]: any;
};
